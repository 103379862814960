<template>
  <transition>
    <div class="chars-deck mt-4 d-flex align-items-center justify-content-center">
      <img loading="lazy" src="/images/home-card-1.png" class="card-1" />
      <img loading="lazy" src="/images/home-card-2.png" class="card-2" />
      <img loading="lazy" src="/images/home-card-3.png" class="card-3" />
    </div>
  </transition>
</template>

<script lang="ts" setup></script>

<style lang="sass" scoped>
.chars-deck
  -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.1))
  .card-1,
  .card-2,
  .card-3
    position: relative
  .card-1
    z-index: 3
  .card-2
    z-index: 2
    margin-left: -50px
    @media(max-width: 500px)
      margin-left: -150px !important
  .card-3
    z-index: 1
    margin-left: -50px
    @media(max-width: 500px)
      margin-left: -150px !important
</style>
